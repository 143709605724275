// Blockquote 
.bloc-content--quote .bloc-content__content blockquote {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    border-left: none;
}





// Figure & Figcaption
.bloc-album .swiper-container {
    .swiper-slide {
        & > figure {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;

            a {
                display: flex;
                width: 100%;
                height: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .legende {
                display: flex;
                position: absolute;
                bottom: 15px;
                max-width: 70%;
                padding: 10px 40px;
                background: rgba(13, 122, 143, .8);
                font-size: $font-size;
                color: $color-white; 
            }
        }
    }

    &[data-captions="false"] {
        .swiper-slide {
            & > figure {
                .legende {
                    display: none;
                }
            }
        }
    }

    &[data-sizes-full="1"],
    &[data-sizes-square="1"] {
        .swiper-slide {
            & > figure {
                a {
                    img {
                        object-fit: contain;
                        object-position: 50% 50%;
                    }
                }
            }
        }
    }

    &--basic {
        .swiper-container .swiper-slide {
            & > figure {
                position: relative;
            }
        }
    }
}





// Avoid main content button
.avoid-main-content {
    width: max-content;
    height: 0;
    position: relative;
    display: block;
    color: $color-white;
    text-align: center;
    background: $color-black;
    border-radius: 30px;
    opacity: 0;
    box-sizing: border-box;
    z-index: 1000;
    transition: $duration;

    &:focus {
        height: auto;
        color: $color-white;
        padding: 15px 30px;
        margin: 10px;
        opacity: 1;
    }
}





// Accesconfig
#a42-ac h2 {
	margin: 0 3rem 0 0;
	font-size: 1.75em;
	line-height: 1.3;
    padding-bottom: 0;
    border-bottom: none;
	color: $color-text;
}





// Form 
.gform_wrapper form {
    input:not([type=submit]):not([type=button]),
    select,
    textarea {
        &:focus {
            border-color: $color-black;
        }
    }
}