header {
    position: relative;
}

.headertop {
    width: 100%;
    z-index: 2;
    background-color: $color-white;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items:center;
        height: 80px;
    }

    &__logo {
        background: $color-main;
        width: 215px;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 50px 50px;
        z-index: 10;
        margin-top: 100px;
        transition: all $duration ease;

        svg {
            transform: translateY(-5px);
            opacity: 1;
            transition: all $duration ease;

            &:hover {
                opacity: .7;
            }
        }
    }

    &__title {
        font-size: 0;
        line-height: 0;
    }

    .social {
        position: fixed;
        top: 125px;
        right: 20px;
        z-index: 100;
        display: flex;
        flex-direction: column;

        &__medias {
            margin: 0;
            padding: 0;

            svg {
                width: 40px;
                height: 40px;
                background-color: $color-second;
                border-radius: 100%;
                transition: all $duration ease;

                &:hover {
                    fill: $color-white;
                    background-color: $color-black;
                }
            }

            &:not(:first-child) {
                margin-top: 15px;
            }
        }
    }
}

.header--fixed {
    .headertop {
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 auto;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
        
        &__logo {
            width: 215px;
            height: 90px;
            margin-top: 10px;
            border-radius: 0px 0px 15px 15px;

            svg {
                width: 68px;
                height: 70px;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {

    &__content {
        display: flex;
    }

    &__accessibility {
        margin: 0;
        padding: 0;
        margin-right: 20px;

        svg {
            fill: $color-white;
            background-color: $color-black;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            transition: all $duration ease;
        }

        &:hover {
            svg {
                background-color: $color-main;
            }
        }
    }

    &__search {
        margin: 0;
        padding: 0;
        
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
        
        svg {
            background-color: $color-third;
            fill: $color-black;
            border-radius: 100%;
            margin-right: 10px;
            width: 40px;
            height: 40px;
            transition: all $duration ease;
        }

        div {
            font-size: $font-size--text-xsmall;
            font-weight: $font-weight-bold;
            font-family: $font-family--heading;
            color: $color-black;
        }

        &:hover {
            svg {
                background-color: $color-main;
                fill: $color-white;
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}



//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    .headertop {
        &__logo {
            min-width: 215px;
        }

        .social {
            top: 120px;
        }
    }

    .tools {
        &__search {
            svg {
                margin-right: 0;
            }
            div {
                display: none;
            }
        }
    }

    #nav-main {
        .menu__nav__list {
            margin-left: -25px;

            .nav-main-item {

                &:not(:first-child) {
                    margin-left: 20px;
                }
                
                .nav-main-link {
                    font-size: 18px;
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .headertop {
        position: relative;

        &__container {
            justify-content: unset;
        }

        .social {
            position: unset;
            flex-direction: row;
            height: 80px;
            align-items: center;

            &__medias {
                &:not(:first-child) {
                    margin-top: 0;
                }

                margin-left: 15px;
            }

        }
    }

    .tools {
        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        height: 80px;

        .container--tools {
            display: flex;
            justify-content: flex-end;
            position: relative;

            .tools__content {
                margin: 0;
            }
        }

        &__accessibility {
            margin-right: 15px;
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {


    .headertop {
        box-shadow: 0px 0px 1px transparent, 1px 1px 10px transparent;
        
        &__container {
            flex-wrap: wrap;
        }

        &__logo {
            min-width: 300px;
            margin-top: 0;
            background: none;
            position: relative;
            display: flex;
            justify-content: center;

            &:before {
                position: absolute;
                content: " ";
                width: 191.11px;
                height: 160px;
                top: 0;
                left: 50%;
                background: $color-main;
                border-radius: 0px 0px 50px 50px;
                transform: translateX(-50%);
            }

            svg {
                margin-bottom: 25px;
                width: 113px;
                height: 117px;
            }
        }

        .social {
            height: auto;
            z-index: -1;

            &__medias {
                margin-left: 10px;
            }
        }
    }

    .tools {
        height: auto;

        &__accessibility {
            margin-right: 10px;
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }
}
