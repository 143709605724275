.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 500px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-white;
        padding: 30px 0;
    }

    &__title {
        color: $color-main;
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
        }
    }

    &__intro {
        color: $color-text;
        font-family: $font-family;
        line-height: 24px;
        font-weight: $font-weight-normal;
        font-size: $font-size--5;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 100px;
            height: 2px;
            background-color: $color-main;
            bottom: -20px;
            left: 0;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 380px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            margin-top: 160px;
            height: 255px;
        }
    }

}
