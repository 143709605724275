.footer {
    display: flex;
    flex-direction: column;

    &__container {
        display: flex;
        gap: 90px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeTrait1.svg);
            bottom: -95px;
            right: -170px;
            width: 185px;
            height: 155px;
            background-repeat: no-repeat;
            z-index: -2;
        }
    }

    &__left {
        position: relative;
        width: 320px;

        &:before {
            position: absolute;
            content: "";
            width: 730px;
            height: 620px;
            top: -65px;
            left: -415px;
            background: $color-main;
            border-radius: 0 188px 0 0;
            z-index: 0;
        }

        &:after {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeTrait2.svg);
            top: -40px;
            right: -50px;
            width: 155px;
            height: 185px;
            background-repeat: no-repeat;
            z-index: 1;
        }

        &--logo {
            z-index: 10;
            position: relative;

            svg {
                width: 140px;
                height: 145px;
            }
        }
    }

    &__coordonnees {
        z-index: 10;
        position: relative;

        &--title {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--4;
            color: $color-white;
            margin-top: 30px;
        }

        &--text {
            display: flex;
            flex-direction: column;
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: $font-size--text-small;
            color: $color-white;
            line-height: 22px;
            margin-top: 15px;

            a {
                width: fit-content;
                position: relative;

                &:before {
                    position: absolute;
                    content: "";
                    width: 0; 
                    height: 2px;
                    left: 0;
                    bottom: -5px;
                    background-color: $color-white;
                    transition: all $duration ease;
                }
                &:hover {
                    color: $color-white;
                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__button {
        z-index: 10;
        position: relative;
        color: $color-text;
        background-color: $color-white;
        font-family: $font-family--heading;
        font-weight: $font-weight-bold;
        font-size: $font-size--text-xsmall;
        display: flex;
        align-items: center;
        text-align: center;
        width: fit-content;
        padding: 15px 25px;
        text-transform: uppercase;
        border-radius: 5px;
        margin: 30px 0 50px;
        border: 1px solid $color-white;
        transition: all $duration ease;

        svg {
            fill: $color-main;
            width: 26px;
            height: 26px;
            margin-right: 10px;
        }

        &:hover {
            background-color: $color-main;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }

    &__right {
        margin-top: 60px;
    }

    &__horaires {
        &--title {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--4;
            color: $color-text;
        }

        &--text {
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: $font-size--text-small;
            color: $color-text;
            line-height: 22px;
            margin-top: 10px;
        }
    }

    &__labels {
        &--title {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--4;
            color: $color-text;
            margin: 0;
            margin-top: 60px;
        }

        &--content {
            margin-top: 20px;

            img {
                &:not(:last-child) {
                    margin-right: 35px;
                }
            }
        }
    }

    &__menu {
        padding: 35px 0;

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 730px;
            gap: 10px 0;
        }

        li {
            position: relative;
            padding: 0 19px;

            &:first-child {
                padding-left: 0;
            }

            a {
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: $font-size--text-small;
                color: $color-text;
                &:hover {
                        color: $color-second;
                }
            } 

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 2px;
                height: 12px;
                background: $color-main;
                transform: translateY(-50%);
            }

            &:nth-child(5) {
                padding-left: 0;
            }

            &:last-child::before {
                background :transparent;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

.footer {
    &__container {
        gap: 120px;

        &:before {
            bottom: -85px;
            right: -130px;
        }
    }

    &__left {
        &:before {
            left: -440px;
        }

        &:after {
            top: -60px;
            right: -90px;
        }

        &--logo {
            margin-top: 40px;
        }
    }

    &__button {
        margin: 30px 0 60px;
    }

    &__menu {
        ul {
            justify-content: flex-start;
            width: 100%;
            margin-top: 10px;
        }

        li:nth-child(5) {
            padding-left: 19px;

            &:before {
                display: flex;
            }
        }

        li:nth-child(4) {
            padding-left: 0;
        }

        li:nth-child(3) {
            &:before {
                display: none;
            }
        }
    }

    &__labels--content img:not(:last-child) {
        margin-right: 15px;
    }
}

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        &__container {
            gap: 100px;

            &:before {
                bottom: -95px;
                right: -155px;
            }
        }

        &__left {
            min-width: 210px;

            &:before {
                left: -460px;
                height: 640px;
            }

            &--logo {
                margin-top: 70px;
            }
        }

        &__labels {
            &--title {
                margin-top: 20px;
            }

            &--content {
                display: flex;
                flex-wrap: wrap;
                gap: 10px 20px;

                img:nth-child(1) {
                    order: 1;
                }
                img:nth-child(2) {
                    order: 3;
                    height: fit-content;
                }
                img:nth-child(3) {
                    order: 4;
                }
                img:nth-child(4) {
                    order: 2;
                }
            }
        }

        &__coordonnees--title {
            margin-top: 40px;
        }

        &__menu {
            padding: 35px 0 15px;

            ul {
                margin-top: 0;
            }

            li:nth-child(4) {
                padding-left: 19px;
                &:before {
                    display: none;
                }
            }

            li:nth-child(3) {
                padding-left: 0;

                &:before {
                    display: block;
                }
            }

            li:nth-child(5), li:nth-child(2) {
                padding-left: 0;
            }

            li:nth-child(2), li:nth-child(1) {
                &:before {
                    display: none;
                }
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
        &__container {
            flex-direction: column;
            gap: 0;

            &:before {
                display: none;
            }
        }

        &__left {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 100%;
            width: 100%;

            &:before {
                left: -170px;
                height: 520px;
                width: 640px;
                border-radius: 0;
                top: 0;
                z-index: -1;
            }

            &:after {
                top: -52px;
                right: -140px;
                z-index: -1;
            }

            &--logo {
                margin-top: 60px;
            }
        }

        &__right {
            margin-top: 25px;
            text-align: center;
        }

        &__coordonnees {
            text-align: center;
        }

        &__horaires {
            &--text {
                font-size: 14px;
            }
        }

        &__labels {
            &--content {
                display: flex;
                justify-content: center;
                gap: 10px 0;

                img:nth-child(1) {
                    margin-right: 20px;
                }

                img:nth-child(3) {
                    margin-right: 9px;
                }
            }
        }

        &__menu ul {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}