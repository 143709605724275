.breadcrumb {

    nav {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeTrait3.png);
            top: -33px;
            left: -85px;
            width: 106px;
            height: 130px;
            background-repeat: no-repeat;
            z-index: 1;
        }
    }

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 50px;
        width: fit-content;
        background: $color-third;
        border-radius: 5px;
        padding: 0 15px;
        margin-top: -25px;
        z-index: 1;
        position: relative;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-gray;
        font-weight: $font-weight-normal;
        padding: 5px 15px;
        font-family: $font-family;
        font-size: $font-size--text-small;
        color: $color-text;
        position: relative;

        &:not(:last-child) {
            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/Pictos/ic-nav-chevron-bottom.svg);
                background-color: $color-black;
                mask-repeat: no-repeat;
                top: 15px;
                right: -10px;
                width: 22px;
                height: 22px;
                transform: rotate(270deg);
                mask-size: 22px;
            }
        }

        &:hover {
            a {
                text-decoration: underline;
                color: $color-text;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;

            &:before {
                top: 8px!important;
            }
        } 
    }

}
