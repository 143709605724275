//=================================================================================================
// Buttons in general
//=================================================================================================
#{$btn} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    margin: 5px 14px 5px 0;
    padding: $btn-padding;
    background: transparent;
    border: none;
    border-radius: $btn-border-radius;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    color: $btn-color;
    text-transform: $btn-text-transform;
    text-decoration: none;
    text-align: center;
    transition: all $duration ease;

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        // color: $btn-color--hover;
        // background: $btn-bg--hover;
        // border-color: $btn-border-color--hover;

        // svg {
        //     fill: $btn-color--hover;
        // }
    }

    svg {
        width: 26px;
        height: 26px;
        fill: $color-white;
        transition: all $duration ease;
    }
}

.grid-center-noGutter {
    margin-top: 35px;

    .button {
        font-family: $font-family--heading;
        font-size: $font-size--text-xsmall;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color-main;
        box-shadow: 0px 0px 1px rgba(169, 61, 53, 0.05), 1px 1px 10px rgba(100, 19, 12, 0.2);
        border: 1px solid transparent;
        transition: all $duration ease;
        height: 55px;
        background-color: $color-white;

        svg {
            width: 24px;
            height: 24px;
            fill: $color-main;
            margin-right: 10px;
        }

        &:hover {
            border-color: $color-main;
            box-shadow: 0px 0px 1px transparent, 1px 1px 10px transparent;
        }
    }
}


//=================================================================================================
// Buttons specifics
//=================================================================================================
.button {
    &--filter,
    &--toggler,
    &--toggler:active,
    &--toggler:focus,
    &--tag,
    &--tag--medium,
    &--tag--low {
        font-family: $font-family--heading;
        font-size: $font-size--text-xsmall;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color-main;
        border: 2px solid transparent;
        transition: all $duration ease;
        height: 55px;
        background-color: $color-white;
        padding: 15px;

        svg {
            width: 24px;
            height: 24px;
            fill: $color-main;
            margin-right: 10px;
        }

        &:hover {
            border-color: $color-main;
            box-shadow: 0px 0px 1px transparent, 1px 1px 10px transparent;
        }
    }


    //===============================================
    // Button secondary (borders, neutral)
    //===============================================
    &--secondary {
        border: 2px solid $color-main;
        background-color: $color-main;
        border-radius: 5px;
        height: 55px;

        svg {
            margin-right: 8px;
        }

        &:hover {
            color: $color-main;
            background-color: transparent;

            svg {
                fill: $color-main;
            }
        }
    }


    //===============================================
    // Button variant 1 (Background and color change)
    //===============================================
    &--variant {
        color: $btn-variant-color;
        background: $btn-variant-bg;
        border-color: $btn-variant-border-color;

        svg {
            fill: $btn-variant-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-variant-color--hover;
            background: $btn-variant-bg--hover;
            border-color: $btn-variant-border-color--hover;

            svg {
                fill: $btn-variant-color--hover;
            }
        }
    }


    //===============================================
    // Button filter
    //===============================================
    &--filter {
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 13px;
        color: $color-text;
        background: $btn-filter-bg;
        border-color: $btn-filter-border-color;
        margin-right: 10px;
        border-radius: 20px 15px;
        height: 35px;

        svg {
            fill: $btn-filter-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-filter-color--hover;
            background: $btn-filter-bg--hover;
            border-color: $btn-filter-border-color--hover;
        }
    }


    //===============================================
    // Button toggler
    //===============================================
    &--toggler,
    &--toggler:active,
    &--toggler:focus {
        border-color: $color-text;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 13px;
        color: $color-text;
        background: transparent;
        border-color: $color-text;
        margin-right: 10px;
        border-radius: 30px;
        height: 35px;

        svg {
            fill: $color-text;
        }

        &.is-open {
            svg {
            transform: rotate(180deg);
            }
        }

        &:hover {
            color: $color-main;
            border-color: $color-main;

            svg {
                fill: $color-main;
            }
        }
    }


    //===============================================
    // Button tag
    //===============================================
    &--tag,
    &--tag--medium,
    &--tag--low {
        margin: 0 5px 10px 0;
        background-color: $btn-tag-bg;
        border-color: $btn-tag-border-color;
        color: $btn-tag-color;

        &:hover, &:focus {
            background-color: $btn-tag-bg--hover;
            border-color: $btn-tag-border-color--hover;
            color: $btn-tag-color--hover;
        }
    }

    &--tag--medium {
        opacity: 0.8;
    }

    &--tag--low {
        opacity: 0.6;
    }


    //===============================================
    // Button empty
    //===============================================
    &--empty {
        background: $btn-empty-bg;
        border-color: $btn-empty-border-color;
        color: $btn-empty-color;

        svg {
            fill: $btn-empty-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background: $btn-empty-bg--hover;
            border-color: $btn-empty-border-color--hover;
            color: $btn-empty-color--hover;

            svg {
                fill: $btn-empty-color--hover;
            }
        }
    }

    //===============================================
    // Button empty
    //===============================================
    &--primary {
        background: $color-main;
        border: 2px solid $color-main;
        color: $color-white;

        svg {
            fill: $color-white;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background: transparent;
            color: $color-main;

            svg {
                fill: $color-main;
            }
        }
    }
}

    


//===============================================
// Specifics links
//===============================================
.link-document {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 20px;
    cursor: pointer;
    font-family: $font-family;
    font-weight: $font-weight;
    font-size: $font-size--text;
    line-height: $line-height;
    text-decoration: underline;
    text-transform: none;
    color: $color--card;
    transition: all $duration ease;
    font-size: $font-size--text-small;

    svg {
        width: 30px;
        height: 30px;
        background: $color-third;
        border-radius: 50%;
        fill: $color-text;
        margin-right: 10px;
    }

    &:hover {
        text-decoration: none;
    }
}

#gform_submit_button_1,
#submit-prop_events,
#submit-prop_documents,
#submit-prop_contacts,
#submit-prop_post,
#load-older-posts,
.gform_button {
    border: 2px solid $color-main;
    background-color: $color-main;
    border-radius: 5px;
    height: 55px;

    svg {
        margin-right: 8px;
    }

    &:hover {
        color: $color-main;
        background-color: transparent;

        svg {
            fill: $color-main;
        }
    }
}


//===============================================
// Animation Voir plus
//===============================================
.showX {
    opacity: 1;
    transition: all 0.30s ease-out;
}

.hideX {
    opacity: 0;
    transition: all 0.30s ease-in;
    pointer-events: none;
}

.sbl-circ-path {
    height: 48px;
    width: 48px;
    color: rgba(90, 90, 90, 0.2);
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #5a5a5a;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

//===============================================
// Reset button
//===============================================
.reset-button-style {
    display: inherit;
    margin: 0;
    padding: 0;
    background: inherit;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
    text-align: inherit;
    transition: none;

    svg {
        width: inherit;
        height: inherit;
        fill: inherit;
        transition: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background: inherit;
        border-color: inherit;
        color: inherit;

        svg {
            fill: inherit;
        }
    }
}
