.front-page {
    position: relative;
    overflow: hidden;
    z-index: 0;
    background: $color-white;
}

.container--home {
    display: flex;
    flex-wrap: wrap;
    width: $large;
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: auto;
        }
    }

    .slideshow {
        height: 590px;
        width: 100%;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: none;
        }

        &__title {
            margin-bottom: 15px;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Navigation (arrow)
        &__navigation {
            display: none;
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 90px;
            bottom: 50px;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                width: 20px;
                height: 20px;
                margin: 0 0 0 20px;
                transition: .25s;
                background-color: $color-white;
                border-radius: 100%;
                opacity: 1;
    
                &-active {
                    background-color: transparent;
                    width: 15px;
                    height: 15px;
                    border: 2px solid $color-white;
                }

                &:hover, &:focus {
                    background-color: $color-second;
                }
            }
        }
    
    }
}

//======================================================================================================
// PROFIL
//======================================================================================================

.profil {

    margin-top: -35px;
    z-index: 10;
    position: relative;

    &__select {
        display: flex;
        justify-content: flex-start;
        position: relative;
        background-color: $color-third;
        padding: 15px 20px;
        width: 650px;
        height: 60px;
        z-index: 2;
        transition: all $duration ease;

        svg:first-child {
            width: 30px;
            height: 30px;
            background-color: $color-black;
            border-radius: 100%;
            margin-right: 10px;
            padding: 2px;
        }

        .label {
            font-weight: $font-weight-bold;
            font-size: $font-size--text;
            color: $color-black;
            font-family: $font-family--heading;
        }

        .description {
            font-weight: $font-weight-normal;
            font-size: $font-size--text-small;
            color: $color-black;
            text-transform: none;
            margin-left: 20px;
        }

        svg:last-child {
            width: 28px;
            height: 28px;
            right: 20px;
            background-color: $color-black;
            border-radius: 100%;
            position: absolute;
            margin-right: 0;
            padding: 0;
        }

        &:hover, &:focus, &:active {
            background-color: $color-second;

            svg:first-child {
                fill: $color-white;
            }

            .label,.description {
                color: $color-white;
            }
        }
    }

    &__list {
        display: none;
        position: absolute;
        background-color: $color-black;
        width: 650px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        top: 57px;

        li {
            border-top: 1px solid $color-white
        }

        a {
            color: $color-white;
            font-size: $font-size--text-small;
            padding: 15px 25px;
            text-decoration: none;
            display: block;
        
            &:hover, &:focus {
                padding-left: 35px;
            }
        }

        &.show-profil {
            display: block;
        }
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    margin-top: -35px;
    background-color: $color-gray;
    height: 500px;
    position: relative;

    &__container {
        display: flex;
        justify-content: space-between;
        gap: 60px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeTrait1.svg);
            top: -35px;
            left: -115px;
            width: 185px;
            height: 155px;
            background-repeat: no-repeat;
            z-index: 1;
        }
    }

    &__left {
        margin-top: 70px;
        max-width: 650px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeTrait1.svg);
            bottom: -10px;
            right: -170px;
            width: 185px;
            height: 155px;
            background-repeat: no-repeat;
            z-index: 0;
        }

        &--list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 20px;
        }

        &--item {
            width: 140px;
            height: 180px;
            padding: 25px 10px 15px;
            box-shadow: unset;
            border-radius: 35px 10px 35px 35px;
            transition: all $duration ease;

            &:hover, &:focus {
                box-shadow: 0 0 15px rgba(13, 122, 143, .2);

                .access__left--label:before {
                    width: 64px;
                }
            }
        }

        &--link {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &--label {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--text-xsmall;
            text-transform: uppercase;
            line-height: 16.8px;
            text-align: center;
            max-width: 112px;
            height: 60px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;

            &:before {
                position: absolute;
                content: "";
                width: 40px;
                height: 2px;
                top: -5px;
                background-color: $color-main;
                transition: all $duration ease;
            }
        }
    }

    &__right {
        width: 470px;
        height: 570px;
        background-color: $color-main;
        border-radius: 166px 70px 151px 118px;
        margin-top: -30px;
        z-index: 10;
        padding: 60px 80px 80px;
        color: $color-white;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &--title {
            font-size: $font-size--1;
            color: $color-white;
            position: relative;
            margin: 0;

            &:before {
                position: absolute;
                content: "";
                width: 25px;
                height: 25px;
                top: 7px;
                left: -7px;
                background-color: $color-third;
                border-radius: 100%;
                z-index: -1;
            }
        }

        &--list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 40px 50px;
            margin-top: 30px;
        }

        &--item {
            width: 120px;
            height: 145px;
            position: relative;

           &:before {
                position: absolute;
                content: "";
                width: 120px;
                height: 100px;
                top: 0;
                left: 0;
                background-color: $color-white;
                opacity: 0.1;
                border-radius: 143px 74px 150px 154px;
                transition: all $duration ease;
                z-index: -3;
           }

           &:hover, &:focus {
                &:before {
                    border-radius: 143px 150px 150px 74px;
                }
                .access__right--label {
                    color: $color-third;
                }
           }
        }

        &--link {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        svg {
            margin: 10px 0 25px;
        }

        &--label {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--text-xsmall;
            text-transform: uppercase;
            line-height: 16.8px;
            text-align: center;
            max-width: 112px;
            transition: all $duration ease;
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    margin-top: 80px;

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight-light;
        font-size: $font-size--1;
        color: $color-black;
        position: relative;
        margin: 0;

        span {
            font-weight: $font-weight-bold;
        }

        &:before {
            position: absolute;
            content: "";
            width: 25px;
            height: 25px;
            top: 7px;
            left: -10px;
            background-color: $color-second;
            border-radius: 100%;
            z-index: -1;
        }
    }

    &__list {
        display: flex;
        gap: 30px;

        &--small {
            gap: 30px;
            display: flex;
            flex-direction: column;
        }
    }

    &__item {
        &--big {
            max-width: 570px;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeRond1.png);
                top: 85px;
                left: -85px;
                width: 225px;
                height: 190px;
                background-repeat: no-repeat;
                z-index: 0;
            }

            > a {
                &:hover, &:focus {
                    cursor: pointer;
                    .news__content--big img {
                        transform: scale(1.1);
                    }
    
                    .notimg--big img {
                        transform: scale(1.1);
                    }
    
                    .news__info--big {
                        border-radius: 20px 80px 105px;
                    }
    
                    .news__link--big {
                        color: $color-white;
        
                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &--small {
            display: flex;
            width: 570px;

            > a {
                display: flex;
            }

            &:hover, &:focus {
                cursor: pointer;

                .news__content--small img {
                    transform: scale(1.1);
                }

                .notimg--small IMG {
                    transform: scale(1.1);
                }

                .news__link--small {
                    color: $color-text;
    
                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__content {
        &--small {
            width: 230px;
            min-width: 230px;
            max-width: 230px;
            height: 140px;
            overflow: hidden;

            img {
                transform: scale(1);
                transition: all .5s ease;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--big {
            position: relative;
            overflow: hidden;
            width: 570px;
            height: 360px;

            img {
                transform: scale(1);
                transition: all .5s ease;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .notimg {
        &--big {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
            background-position: center;
            width: 570px;
            height: 360px;

            img {
                transform: scale(1);
                transition: all .5s ease;
            }

        }

        &--small {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
            background-position: center;
            width: 230px;
            height: 140px;

            img {
                transform: scale(1);
                transition: all .5s ease;
            }
        }
    }

    &__info {
        &--big {
            background-color: $color-second;
            width: 525px;
            max-height: 290px;
            padding: 50px 70px;
            border-radius: 137.5px 109px 229.5px;
            margin-top: -80px;
            margin-left: 20px;
            transition: all 0.5s ease;
            position: relative;
            -webkit-transform: translate3d(0,0,0);
            z-index: 999;
        }

        &--small {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__titlep {
        &--small {
            margin: 0;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--text-medium;
            line-height: 24px;
            color: $color-second;
            margin-bottom: 20px;
        }

        &--big {
            font-family: $font-family--heading;
            font-size: $font-size--3;
            font-weight: $font-weight-bold;
            color: $color-white;
            line-height: 32px;
            margin: 0;
            margin-bottom: 30px;
            position: relative;
            text-transform: none;

            &:before {
                position: absolute;
                content: "";
                width: 70px;
                height: 2px;
                bottom: -15px;
                left: 0;
                background-color: $color-white;
                transition: all $duration ease;
            }
        }
    }

    &__introduction {
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: $font-size--text-xsmall;
        line-height: 20px;
        color: $color-white;
        margin-bottom: 15px;
        text-transform: none;
    }

    &__link {
        &--small {
            font-family: $font-family--heading;
            font-size: $font-size--text-xsmall;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            position: relative;
            width: fit-content;

            svg {
                width: 12px;
                height: 12px;
                fill: $color-second;
                margin-right: 2px;
            }

            &:before {
                position: absolute;
                content: "";
                width: 0; 
                height: 2px;
                left: 0;
                bottom: -5px;
                background-color: $color-black;
                transition: all $duration ease;
            }

            &:hover, &:focus {
                color: $color-black;

                &:before {
                    width: 100%;
                }
                
            }
        }

        &--big {
            font-family: $font-family--heading;
            font-size: $font-size--text-xsmall;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-transform: uppercase;
            position: relative;
            width: fit-content;

            svg {
                width: 12px;
                height: 12px;
                fill: $color-white;
                margin-right: 2px;
            }

            &:before {
                position: absolute;
                content: "";
                width: 0; 
                height: 2px;
                left: 0;
                bottom: -5px;
                background-color: $color-white;
                transition: all $duration ease;
            }
        }
    }

    &__button {
        font-family: $font-family--heading;
        font-size: $font-size--text-xsmall;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color-black;
        box-shadow: 0px 0px 1px rgba(23, 127, 148, 0.05), 1px 1px 10px rgba(23, 127, 148, 0.2);
        border: 1px solid transparent;
        transition: all $duration ease;
        background-color: $color-white;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeRond2.png);
            top: -65px;
            left: -88px;
            width: 205px;
            height: 185px;
            background-repeat: no-repeat;
            z-index: -1;
        }
        

        svg {
            width: 24px;
            height: 24px;
            fill: $color-second;
            margin-right: 10px;
        }

        &:hover, &:focus {
            border-color: $color-second;
            box-shadow: 0px 0px 1px transparent, 1px 1px 10px transparent;
        }
    }
}

//======================================================================================================
// Agenda
//======================================================================================================

.events {
    margin-top: 75px;

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight-light;
        font-size: $font-size--1;
        color: $color-black;
        position: relative;
        margin: 0;

        span {
            font-weight: $font-weight-bold;
        }

        &:before {
            position: absolute;
            content: "";
            width: 25px;
            height: 25px;
            top: 9px;
            left: -9px;
            background-color: $color-third;
            border-radius: 100%;
            z-index: -1;
        }

        &--big {
            color: $color-white;
            margin: 0;
            font-family: $font-family--heading;
            font-size: $font-size--2;
            font-weight: $font-weight-bold;
            line-height: 41px;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 70px;
                height: 2px;
                bottom: -15px;
                left: 0;
                background-color: $color-third;
            }
        }

        &--small {
            margin: 0;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--text-medium;
            line-height: 24px;
            color: $color-black;
            margin-bottom: 15px;
        }
    }

    &__list {
        &--big {
            margin-top: 10px;
        }

        &--small {
            display: flex;
            gap: 30px;
            margin-top: 30px;
        }
    }

    &__item {
        &--big {
            a {
                display: flex;
            }

            &:hover, &:focus {
                .events__image--big {
                    img {
                        transform: scale(1.1);
                    }
                }

                .notimg--big {
                    img {
                        transform: scale(1.1);
                    }
                }

                .events__link--big {
                    color: $color-white;
    
                    &:before {
                        width: 100%;
                    }
                }

                .events__infos--big {
                    border-radius: 140px 105px 55px 143px;
                }
            }
        }

        &--small {
            display: flex;
            box-shadow: 0px 0px 1px rgba(169, 61, 53, 0.05), 1px 1px 10px rgba(100, 19, 12, 0.2);
            border-radius: 0px 5px 5px 0px;
            width: 570px;
            height: 225px;

            > a {
                display: flex;
                width: 100%;
            }

            &:hover, &:focus {
                .events__image--small {
                    img {
                        transform: scale(1.1);
                    }
                }
                
                .notimg--small {
                    img {
                        transform: scale(1.1);
                    }
                }

                .events__dates--small {
                    border-radius: 50px 25px 40px;
                }

                .events__link--small {
                    color: $color-text;
    
                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__image {
        &--big {
            width: 670px;
            height: 490px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: all 0.5s ease;
                object-fit: cover;
            }
        }

        &--small {
            width: 270px;
            height: 225px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: all 0.5s ease;
                object-fit: cover;
            }
        }
    }

    .notimg {
        &--big {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-bg--image;
            background-position: center;
            width: 670px;
            height: 490px;

            img {
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: all 0.5s ease;
            }

        }

        &--small {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-bg--image;
            background-position: center;
            width: 270px;
            height: 225px;

            img {
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: all 0.5s ease;
            }
        }
    }

    &__content {
        &--small {
            position: relative;
        }
    }

    &__infos {
        &--big {
            width: 550px;
            height: 415px;
            background: $color-main;
            border-radius: 198px 155px 229.5px 143px;
            padding: 95px 70px;
            position: relative;
            margin-left: -50px;
            margin-top: 40px;
            position: relative;
            transition: all 0.5s ease-in-out;

            &:before {
                position: absolute;
                content: "";
                background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeTrait1.svg);
                top: -90px;
                right: 5px;
                width: 185px;
                height: 155px;
                background-repeat: no-repeat;
            }
        }

        &--small {
            position: absolute;
            width: 237px;
            left: 30px;
            bottom: 42px;
        }
    }

    &__dates {
        &--big {
            background: $color-third;
            border-radius: 40px 50px 30px;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 25px 20px;
            position: absolute;
            z-index: 1;
            top: -25px;
            left: 20px;
        }

        &--small {
            background: #8EC8AD;
            border-radius: 40px 50px 30px;
            width: fit-content;
            padding: 10px 20px;
            display: flex;
            margin-top: 15px;
            margin-left: -15px;
            transition: all 0.5s ease-in-out;
        }
    }

    &__date {
        &--big {
            text-align: center;
            text-transform: capitalize;
            display: flex;
            flex-direction: column;
            color: $color-text;

            &.end_date {
                position: relative;
                margin-left: 15px;

                &:before {
                    position: absolute;
                    content: "";
                    mask-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/Pictos/ic-nav-chevron-bottom.svg);
                    background-color: $color-text;
                    mask-repeat: no-repeat;
                    top: 22px;
                    left: -25px;
                    width: 30px;
                    height: 30px;
                    transform: rotate(270deg);
                    mask-size: 35px;
                }
            }
        }

        &--small {
            text-transform: capitalize;

            &.end_date {
                position: relative;
                margin-left: 20px;

                &:before {
                    position: absolute;
                    content: "";
                    mask-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/Pictos/ic-nav-chevron-bottom.svg);
                    background-color: $color-black;
                    mask-repeat: no-repeat;
                    top: 4px;
                    left: -23px;
                    width: 20px;
                    height: 20px;
                    transform: rotate(270deg);
                    -webkit-mask-size: 25px;
                    mask-size: 25px;
                }
            }
        }
    }

    &__day {
        &--big {
            font-family: $font-family;
            font-size: 32px;
            font-weight: $font-weight-black;
        }

        &--small {
            font-family: $font-family;
            font-size: $font-size--text-small;
            font-weight: $font-weight-black;
        }
    }

    &__month {
        &--big {
            font-family: $font-family;
            font-size: $font-size--text-xsmall;
            font-weight: $font-weight-normal;
            margin-top: -5px;
        }

        &--small {
            font-family: $font-family;
            font-size: $font-size--text-small;
            font-weight: $font-weight-normal;
        }
    }

    &__introduction {
        &--big {
            font-family: $font-family;
            font-size: $font-size--text-small;
            font-weight: $font-weight-normal;
            line-height: 22px;
            color: $color-white;
            padding: 30px 0 15px;
        }
    }

    &__link {
        &--big {
            font-family: $font-family--heading;
            font-size: $font-size--text-xsmall;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-transform: uppercase;
            position: relative;
            width: fit-content;

            svg {
                width: 12px;
                height: 12px;
                fill: $color-white;
                margin-right: 2px;
            }

            &:before {
                position: absolute;
                content: "";
                width: 0; 
                height: 2px;
                left: 0;
                bottom: -5px;
                background-color: $color-white;
                transition: all $duration ease;
            }
        }

        &--small {
            font-family: $font-family--heading;
            font-size: $font-size--text-xsmall;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            position: relative;
            width: fit-content;

            svg {
                width: 12px;
                height: 12px;
                fill: $color-main;
                margin-right: 2px;
            }

            &:before {
                position: absolute;
                content: "";
                width: 0; 
                height: 2px;
                left: 0;
                bottom: -5px;
                background-color: $color-black;
                transition: all $duration ease;
            }
        }
    }

    &__buttons {
        gap: 30px;
        margin-top: 50px;
        display: flex;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeTrait1.svg);
            top: -110px;
            left: -75px;
            width: 185px;
            height: 155px;
            background-repeat: no-repeat;
            z-index: -2;
        }
        
    }

    &__button {
        font-family: $font-family--heading;
        font-size: $font-size--text-xsmall;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color-black;
        box-shadow: 0px 0px 1px rgba(169, 61, 53, 0.05), 1px 1px 10px rgba(100, 19, 12, 0.2);
        border: 1px solid transparent;
        transition: all $duration ease;
        min-width: 570px;
        height: 55px;
        margin: 0;
        background-color: $color-white;

        svg {
            width: 24px;
            height: 24px;
            fill: $color-main;
            margin-right: 10px;
        }

        &:hover, &:focus {
            border-color: $color-main;
            box-shadow: 0px 0px 1px transparent, 1px 1px 10px transparent;
        }
    }
}

//======================================================================================================
// AdminAreas
//======================================================================================================

.admin-area {
    margin-top: 75px;
    background-color: $color-gray;
    padding: 10px 0 130px;

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight-light;
        font-size: $font-size--1;
        line-height: 55px;
        color: $color-black;
        position: relative;
        margin: 0;
        transform: translateY(65px);

        span {
            font-weight: $font-weight-bold;
        }

        &:before {
            position: absolute;
            content: "";
            width: 25px;
            height: 25px;
            top: -1px;
            left: -9px;
            background-color: $color-second;
            border-radius: 100%;
            z-index: -1;
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/trelissac/assets/src/images/sprite-images/home/FormeRond3.png);
            top: 85px;
            right: -35px;
            width: 205px;
            height: 185px;
            background-repeat: no-repeat;
            z-index: 0;
        }
    }

    .bloc__wrapper {
        display: flex;

        .bloc__image {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
                position: absolute;
                content: " ";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(13, 122, 143, 0.85);
                opacity: 0;
                transition: all $duration ease;
            }

            img {
                width: 100%;
                height: 100%;
            }

            &--container {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 40px;
                text-align: center;
                opacity: 0;
                transition: all $duration ease;

                figcaption {
                    font-family: $font-family--heading;
                    font-size: 18px;
                    font-weight: $font-weight-bold;
                    line-height: 22px;
                    text-shadow: 1px 1px 20px $color-black;
                    color: $color-white;
                }
    
                svg {
                    width: 20px;
                    height: 20px;
                    filter: drop-shadow( 3px -2px 6px rgba(28, 28, 28, 0.7));
                    fill: $color-white;
                    margin-top: 10px;
                }
            }
        }

        &:nth-child(1) {
            width: 280px;
            display: flex;
            align-items: flex-end;

            .bloc__image {
                &:before {
                    border-radius: 5px 35px 5px 40px;
                }

                img {
                    border-radius: 5px 35px 5px 40px;
                }
            }
        }

        &:nth-child(2) {
            width: 420px;
            height: 245px;

            .bloc__image {
                &:before {
                    border-radius: 85px 5px 5px 5px;
                }

                img {
                    border-radius: 85px 5px 5px 5px;
                }
            }
        }

        &:nth-child(3) {
            width: 350px;
            height: 200px;

            .bloc__image {
                &:before {
                    border-radius: 5px 100px 30px 5px;
                }

                img {
                    border-radius: 5px 100px 30px 5px;
                }
            }
        }

        &:nth-child(4) {
            width: 350px;
            height: 200px;

            .bloc__image {
                &:before {
                    border-radius: 30px 5px 5px 100px;
                }

                img {
                    border-radius: 30px 5px 5px 100px;
                }
            }
        }

        &:nth-child(5) {
            width: 350px;
            height: 200px;

            .bloc__image {
                &:before {
                    border-radius: 5px 5px 50px 5px;
                }

                img {
                    border-radius: 5px 5px 50px 5px;
                }
            }
        }

        &:nth-child(6) {
            width: 420px;
            height: 245px;
            margin-top: -45px;

            .bloc__image {
                &:before {
                    border-radius: 5px 120px 55px 85px;
                }

                img {
                    border-radius: 5px 120px 55px 85px;
                }
            }
        }

        &:hover, &:focus {
            .bloc__image {
                &:before {
                    opacity: 1;
                }

                &--container {
                    opacity: 1;
                }
            }
        }
    }
}

.admin-blocs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 240px;
    margin: 40px 0;

    .bloc {
        display: flex;
        width: 170px;
        height: 110px;
        margin-right: 20px;

        &:nth-child(1),
        &:nth-child(2) {
            width: 370px;
            height: 240px;
        }

        &__wrapper {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;


            &:hover, &:focus {
                .bloc__content {
                    opacity: 1;
                }
            }

            &--video {
                cursor: pointer;

                &::before {
                    content: '\f126';
                    font: normal 34px/1 dashicons;
                    position: absolute;
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: $color-white;
                }
            }

        }

        &__image {
            position: absolute;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

        }

        &__content {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            opacity: 0;
            padding: 0 20px;
            background: rgba(0,0,0,0.5);
            color: $color-white;
            transition: all 0.5s;
        }

        &__title {
            font-size: $font-size--5;
            margin-bottom: 10px;
        }

        .popup-area {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 20;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            background: $color-black--rgba;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }

            .accordion {
                height: 0;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            .close-area-popup {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: absolute;
                top: 40px;
                right: calc((100% - 1170px)/2);
                width: 40px;
                height: 40px;
                background: $color-white;
                border-radius: 50px;

                &::before {
                    content: '\f335';
                    font: normal 30px/1 'dashicons';
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .home-slideshow .slideshow {
        height: 480px;
    }

    .profil {
        &__select, &__list {
            width: 530px;
        }
    }

    .access {
        height: 460px;

        &__container {
            gap: 0;
            &:before {
                top: -60px;
                left: -95px;
            }
        }

        &__left {
            max-width: 530px;
            margin-top: 60px;
            
            &--list {
                gap: 30px 20px;
                margin-top: 25px;
                justify-content: unset;
            }

            &--item {
                width: 115px;
                padding: 0;
                height: fit-content;
            }

            &:before {
                bottom: -40px;
                right: -190px;
            }
        }

        &__right {
            min-width: 380px;
            max-width: 380px;
            height: 520px;
            border-radius: 120px 60px 110px 100px;
            padding: 50px 50px 60px;

            &--list {
                gap: 30px 40px;
                margin-top: 20px;
            }
        }
    }

    .news {
        margin-top: 70px;

        &__list {
            gap: 20px;

            &--small {
                gap: 20px;
            }
        }

        .notimg {
            &--small {
                width: 190px;
                height: 120px;
            }

            &--big {
                width: 460px;
                height: 290px;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__item {
            &--big {
                min-width: 460px;
            }
        }

        &__content {
            &--small {
                min-width: 190px;
                max-width: 190px;
                width: 190px;
                height: 120px;

                img {
                    max-width: 190px;
                    height: 120px;
                }
            }

            &--big {
                width: 460px;
                height: 290px;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__info {
            &--small {
                max-width: 250px;
            }

            &--big {
                width: 430px;
                max-height: 250px;
                padding: 40px 55px;
                margin-top: -50px;
                margin-left: 15px;
                border-radius: 110px 80px 170px 100px;
            }
        }

        &__titlep {
            &--big {
                margin: 0 0 20px;

                &:before {
                    bottom: -10px;
                }
            }
        }

        &__introduction {
            margin-bottom: 0;
        }

        &__button {
            width: 460px;
            margin-top: 18px;

            &:before {
                top: -70px;
                left: -40px;
            }
        }
    }

    .events {
        &__title {
            padding: 0;

            &--big {
                font-size: $font-size--3;
                line-height: 32px;
            }
        }

        &__list {
            &--small {
                gap: 20px;
            }
        }

        &__item {
            &--big {
                align-items: flex-end;

                &:hover, &:focus {
                    .events__infos--big {
                        border-radius: 130px 120px 50px 110px;
                    }
                }
            }

            &--small {
                width: 460px;
                height: 190px;
            }
        }

        &__image {
            &--big {
                width: 540px;
                height: 400px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &--small {
                width: 220px;
                height: 190px;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .notimg {
            &--big {
                width: 540px;
                height: 400px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &--small {
                width: 220px;
                height: 190px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &__infos {
            &--big {
                width: 450px;
                height: 370px;
                border-radius: 170px 140px 200px 120px;
                padding: 95px 50px;
            }

            &--small {
                bottom: 25px;
                width: 200px;
                left: 20px;
            }
        }

        &__dates {
            &--big {
                padding: 10px 25px 15px;
                border-radius: 30px 40px 25px;
                top: -10px;
            }

            &--small {
                padding: 1px 15px 5px;
                margin-top: 7px;
                margin-left: -20px;
            }
        }

        &__buttons {
            gap: 20px;
            margin-top: 40px;

            &:before {
                top: -110px;
                left: -60px;
            }
        }

        &__button {
            min-width: 460px;
        }
    }

    .admin-area {
        margin-top: 65px;

        &__title {
            margin-bottom: 30px;
        }

        &__content {
            gap: 20px;

            &:before {
                display: none;
            }
        }

        .bloc__wrapper {

            .bloc__image--container {
                padding: 0 35px;
            }

            &:nth-child(1) {
                width: 230px;
                height: 184px;
            }

            &:nth-child(2) {
                width: 320px;
                height: 185px;
            }

            &:nth-child(3) {
                width: 290px;
                height: 165px;
            }

            &:nth-child(4) {
                width: 275px;
                height: 175px;
            }

            &:nth-child(5) {
                width: 275px;
                height: 175px;
            }

            &:nth-child(6) {
                width: 350px;
                height: 195px;
                margin-top: -20px;
            }
        }
    }
    
}


// 960
@media screen and (max-width: $medium) {

    .front-page {
        margin-top: 0;
    }

    .home-slideshow {
        position: relative;

        .slideshow {
            height: 390px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__pagination {
                right: 10px;
            }
        }
    }

    .profil {
        &__select {
            width: 100%;
        }

        &__list {
            width: 620px;
        }
    }

    .access {
        height: 770px;

        &__container {
            flex-direction: column;
            gap: 60px;

            &:before {
                top: -70px;
            }
        }

        &__left {
            max-width: 100%;

            &:before {
                bottom: -440px;
                left: -50px;
            }

            &--list {
                gap: 5px;
                justify-content: center;
                margin-top: 0;
            }

            &--item {
                width: 140px;
                padding: 15px 0;
            }
        }

        &__right {
            min-width: 100%;
            height: 360px;
            padding: 50px 30px 70px;
            border-radius: 120px 110px 60px 100px;

            &--list {
                gap: 25px;
            }
        }
    }

    .news {
        margin-top: 125px;

        &__list {
            flex-direction: column;
            gap: 0;

            &--small {
                margin-top: 30px;
            }
        }

        &__item {
            &--big {
                min-width: 100%;

                &:before {
                    left: -75px;
                }
            }
        }

        &__content {
            &--big {
                width: 620px;
                height: 390px;
            }
            &--small {
                width: 260px;
                height: 165px;
            }
        }

        &__info {
            &--big {
                width: 520px;
                max-height: 280px;
                margin-top: -150px;
                margin-left: 50px;
            }
        }

        .notimg {
            &--big {
                width: 620px;
                height: 390px;
            }
            &--small {
                width: 260px;
                height: 165px;
            }
        }

        &__content {
            &--small {
                min-width: 260px;
                height: 165px;

                img {
                    min-width: 100%;
                    height: 100%;
                }
            }
        }

        &__info {
            &--small {
                min-width: 340px;
            }
        }

        &__button {
            width: 460px;
            margin: 20px auto 0;
        }
    }

    .events {
        &__title {
            &--small {
                width: 90%;
            }
        }

        &__item {
            &--big {
                flex-direction: column;
                position: relative;
            }
        }

        &__content {
            &--big {
                position: absolute;
                top: 295px;
                left: 100px;
            }
        }

        &__infos {
            &--big {
                width: 520px;
                height: 370px;
                padding: 95px;

                &:before {
                    top: 60px;
                    right: -140px;
                }
            }

            &--small {
                bottom: 50px;
            }
        }

        .notimg {
            &--big {
                width: 620px;
                height: 460px;
            }

            &--small {
                width: 300px;
                height: 245px;
            }
        }

        &__image {
            &--big {
                width: 620px;
                height: 460px;
            }

            &--small {
                width: 300px;
                height: 245px;
            }
        }

        &__list {
            &--small {
                margin-top: 270px;
                flex-direction: column;
            }
        }

        &__item {
            &--small {
                width: 100%;
                height: 245px;
            }
        }

        &__dates {
            &--small {
                margin-top: 20px;
            }
        }

        &__buttons {
            margin-top: 50px;

            &:before {
                top: -125px;
                left: -85px;
            }
        }

        &__button {
            min-width: 300px;
        }
    }

    .admin-area {
        margin-top: 50px;

        &__title {
            margin-bottom: 20px;
        }

        .bloc__wrapper {

            &:nth-child(1) {
                width: 250px;
                height: 200px;
            }

            &:nth-child(2) {
                width: 350px;
                height: 200px;
            }

            &:nth-child(3) {
                width: 300px;
                height: 190px;

                .bloc__image {
                    &:before {
                        border-radius: 30px 5px 5px 40px;
                    }

                    img {
                        border-radius: 30px 5px 5px 40px;
                    }
                }
            }

            &:nth-child(4) {
                width: 300px;
                height: 190px;

                .bloc__image {
                    &:before {
                        border-radius: 5px 5px 30px 5px;
                    }

                    img {
                        border-radius: 5px 5px 30px 5px;
                    }
                }
            }

            &:nth-child(5) {
                width: 260px;
                height: 180px;

                .bloc__image {
                    &:before {
                        border-radius: 30px 5px 5px 80px;
                    }

                    img {
                        border-radius: 30px 5px 5px 80px;
                    }
                }
            }

            &:nth-child(6) {
                width: 340px;
                height: 180px;
                margin-top: 0;

                .bloc__image {
                    &:before {
                        border-radius: 5px 25px 70px 45px;
                    }

                    img {
                        border-radius: 5px 25px 70px 45px;
                    }
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .front-page {
        margin-top: 160px;
    }

    .home-slideshow {
        .slideshow {
            height: 260px;
            
            &__pagination {
                width: 100%;
                right: 0;
            }

            &__content {
                left: 30px;
                right: 30px;
                width: auto;
            }
        }
    }

    .profil {
        &__select .description {
            display: none;
        }

        &__list {
            width: 300px;
        }
    }

    .access {
        height: 1245px;

        &__left {
            gap: 10px;

            &:before {
                bottom: -590px;
                left: -90px;
            }

            &--item {
                width: 125px;
                height: 165px;
                padding: 10px 5px 0;
            }
        }

        &__right {
            max-width: 380px;
            height: 520px;
            padding: 50px 50px 70px;
            border-radius: 120px 60px 110px 100px;
            margin-left: -40px;
        }
    }

    .news {
        margin-top: 115px;

        &__title {
            font-size: 3.025rem;
            padding-bottom: 30px;
        }

        &__list {
            &--big {
                margin-top: 40px;
            }
        }

        &__item {
            &--big {
                &:before {
                    top: 60px;
                    left: -95px;
                }
            }

            &--small {
                width: 100%;
                flex-wrap: wrap;

                > a {
                    flex-direction: column;
                }
            }
        }

        &__list {
            &--small {
                gap: 30px;
            }
        }

        &__info {
            &--big {
                padding: 35px 30px 40px;
                border-radius: 90px 60px 130px;
                width: 300px;
                max-height: 260px;
                margin-top: -55px;
                margin-left: 0;
            }

            &--small {
                padding-left: 0;
                width: 100%;
                min-width: 100%;
                margin-top: 15px;
            }
        }

        &__titlep {
            margin: 0 0 15px;
        }

        &__content {
            &--big {
                width: 300px;
                height: 190px;
            }

            &--small {
                width: 300px;
                height: 190px;
            }
        }

        .notimg {
            &--big {
                width: 300px;
                height: 190px;
            }

            &--small {
                width: 300px;
                height: 190px;
            }
        }

        &__button {
            width: 100%;
            margin: 5px auto 0;

            &:before {
                left: -125px;
            }
        }
    }

    .events {
        margin-top: 80px;

        &__title {
            &--small {
                width: 100%;
            }
        }

        &__list {
            &--small {
                margin-top: 395px;
            }
        }

        &__item {
            &--big {
                &:hover, &:focus {
                    .events__infos--big {
                        border-radius: 75px 106px 35px 75px;
                    }
                }
            }

            &--small {
                flex-direction: column;
                height: 395px;

                > a {
                    flex-direction: column;
                }
            }
        }

        &__image {
            &--big {
                width: 300px;
                height: 225px;
            }

            &--small {
                width: 300px;
                height: 245px;
            }
        }

        .notimg {
            &--big {
                width: 300px;
                height: 225px;
            }

            &--small {
                width: 300px;
                height: 245px;
            }
        }

        &__content {
            &--big {
                top: 190px;
                right: 0;
                left: unset;
            }

            &--small {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 150px;
                background: white;
            }
        }

        &__infos {
            &--big {
                margin-top: 0;
                width: 310px;
                height: 400px;
                border-radius: 70px 100px 140px 60px;
                padding: 65px 40px 40px 30px;

                &:before {
                    top: -10px;
                    right: -110px;
                }
            }

            &--small {
                position: unset;
                margin-bottom: 30px;
                width: 260px;
            }
        }

        &__introduction {
            &--big {
                padding: 25px 0 10px;
            }
        }

        &__dates {
            &--big {
                padding: 3px 30px 8px;
                top: -25px;
                left: 0px;
            }

            &--small {
                margin-top: 0;
                margin-left: 0;
                transform: translateY(-20px);
            }
        }

        &__date {
            &--big {
                &.end_date {
                    &:before {
                        top: 15px;
                    }
                }
            }
        }

        &__day {
            &--big {
                font-size: 28px;
            }
        }

        &__buttons {
            margin-top: 40px;
            flex-direction: column;
        }
    }

    .admin-area {
        margin-top: 35px;
        padding: 0 0 60px;

        &__title {
            margin-top: -10px;
            margin-bottom: 90px;
        }

        .bloc__wrapper {

            &:nth-child(1) {
                width: 100%;
                height: 170px;
            }

            &:nth-child(2) {
                width: 100%;
                height: 190px;

                .bloc__image {
                    &:before {
                        border-radius: 30px 5px 5px 40px;
                    }

                    img {
                        border-radius: 30px 5px 5px 40px;
                    }
                }
            }

            &:nth-child(3) {
                width: 100%;
                height: 170px;

                .bloc__image {
                    &:before {
                        border-radius: 75px 5px 5px 5px;
                    }

                    img {
                        border-radius: 75px 5px 5px 5px;
                    }
                }
            }

            &:nth-child(4) {
                width: 100%;
                height: 190px;
            }

            &:nth-child(5) {
                width: 100%;
                height: 180px;
            }

            &:nth-child(6) {
                width: 100%;
                height: 180px;
            }
        }
    }
}